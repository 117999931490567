import { Fragment } from "react";
import "./experience.css";
import { Badge, Stack, Accordion } from "react-bootstrap";

function ExperienceAccord() {
    const newExperience = [
        {
            "office": "Mekari - Jakarta, Indonesia",
            "key": "mkr",
            "experiences": [
                {
                    "title": "Senior SDET I",
                    "duration": "Jul 2024 - now",
                    "tasks": [
                        "automate and maintain WEB and API test cases in assigned products/tribes",
                        "develop tools to implement distrubuted load testing using k6",
                        "develop tools to generate k6 test script using JSON file",
                        "implement early-security testing using ZAP tools on WEB automation",
                        "improve the load test features on QA Dashboard",
                        "research AI-use on testing process",
                        "maintain QA Dashboard owned by QA/SDET",
                        "participate to maintain Jenkins, and BitBucket pipeline related to automation"
                    ],
                    "tech_stack": ["typescript", "ruby", "selenium", "k6", "docker", "jenkins", "ruby on rails", "zap"]
                },
                {
                    "title": "SDET II",
                    "duration": "Feb 2022 - Jul 2024",
                    "tasks": [
                        "implement and maintain API & Mobile automation for Talenta",
                        "implement new features on QA Dashboard; load test execution, automation count tracker",
                        "create and execute performance test script using k6 or jmeter (on demand only)",
                        "create base structure and implement Playwright automation tools to selected product",
                        "maintain QA Dashboard owned by QA/SDET",
                        "participate to maintain device farm, Jenkins, and automation on deployment pipeline"
                    ],
                    "tech_stack": ["typescript", "ruby", "selenium", "appium", "ruby on rails", "docker", "jenkins", "jmeter", "k6"]
                }
            ]
        },
        {
            "office": "Kata.ai - Jakarta, Indonesia",
            "key": "kata",
            "experiences": [
                {
                    "title": "Quality Assurance Engineer",
                    "office": "Kata.ai",
                    "duration": "Jul 2020 - Feb 2022",
                    "tasks": [
                        "define UAC per-story with approval by the Product Manager (also discussed with all team members)",
                        "create test plan and test cases for each development sprint time",
                        "done the E2E and Web UI testing before releasing new features",
                        "start automation testing using CodeceptJS"
                    ],
                    "tech_stack": ["javascript", "codeceptjs", "docker"]
                },
                {
                    "title": "Backend Engineer",
                    "office": "Kata.ai",
                    "duration": "Mar 2020 - Jul 2020",
                    "tasks": [
                        "develop and maintain backend API for Business Dashboard (WhatsApp API)",
                        "develop, maintain and implement third-party integration that work with Chatbot using Kata Platform"
                    ],
                    "tech_stack": ["javascript", "nestjs", "redis", "rabbitmq", "mysql", "docker"]
                },
                {
                    "title": "Software Engineer Enterprise (Chatbot builder)",
                    "office": "Kata.ai",
                    "duration": "Mar 2019 - Mar 2020",
                    "tasks": [
                        "building, implementing, and maintaining chatbot using Kata Platform",
                        "designing, building, implementing, and maintaining middleware solutions and third-party integrations that work with the chatbot",
                        "works closely with project manager, ux writter, and ux flow-bot to create realistics chatbot flow and conversation"
                    ],
                    "tech_stack": ["javascript", "nestjs", "python", "flask", "mysql"]
                }

            ]
        }
    ]
    return (
        <Fragment>
        <Accordion defaultActiveKey="0" className="mb-3">
          { newExperience.map(function (object, i) {
            return (
                <Accordion.Item eventKey={i}>
                    <Accordion.Header>{object['office']}</Accordion.Header>
                    <Accordion.Body>
                    { object['experiences'].map(function(exp, _i) {
                        return (
                            <Fragment>
                                <h5 className="position">{exp['title']}</h5>
                                <h5 className="duration">{exp['duration']}</h5>

                                <Stack direction="horizontal" gap={2} className="techstack">
                                    { exp['tech_stack'].map(function(tech, _i) {
                                        return <Badge pill bg="info">
                                                {tech}
                                            </Badge>
                                    })}
                                </Stack>
                                <p className="tasks">
                                    responsibilities:
                                    <ul>
                                        { exp['tasks'].map(function(task, _i) {
                                            return <li>{task}</li>
                                        })}
                                    </ul>
                                </p>
                                <br></br>
                            </Fragment>
                        )
                    })}
                    </Accordion.Body>
                </Accordion.Item>
            )
          })}
          </Accordion>
        </Fragment>
    )
}

export default ExperienceAccord;